<template>
	<v-dialog
		v-model="uploadProfile"
		scrollable
		max-width="650"
		max-height="500"
		@click:outside="uploadProfileOff"
		@keydown.esc="uploadProfileOff"
		:fullscreen="$vuetify.breakpoint.xsOnly"
	>	
		<v-card>
			<v-card-title class="headline primary white--text" primary-title>
				{{ dialogTitle }}
				<v-spacer></v-spacer>
					
				<v-btn icon
					@click="uploadProfileOff"
					class="white--text"
				>
					<v-icon small dark>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			
			<v-card-text v-if="dialogContent == 'select'">
				<v-row dense no-gutters class="my-15">
					<v-col cols="6">
						<v-card
							max-width="150"
							min-height="150"
							tile
							elevation="5"
							@mouseover="mouseoverBrowse = true"
							@mouseleave="mouseoverBrowse = false"
							v-bind:class="{ 'primary': !mouseoverBrowse, 'blue darken-1': mouseoverBrowse }"
							class="white--text text-center mx-auto rounded-circle"
							@click="$refs.FileInput.click();"
						>
							<v-icon
								x-large
								dark
								class="mt-11"
							>
								mdi-folder-image
							</v-icon>
							<br/>
							Browse
						</v-card>
						<input ref="FileInput" type="file" style="display: none;" @change="cropImage" />
						<input ref="mobileCamera" type="file" accept="image/*" capture="user" @change="cropImage" style="display: none;" />
					</v-col>
					
					<v-col cols="6">
						<v-card
							max-width="150"
							min-height="150"
							tile
							elevation="5"
							@mouseover="mouseoverCamera = true"
							@mouseleave="mouseoverCamera = false"
							v-bind:class="{ 'primary': !mouseoverCamera, 'blue darken-1': mouseoverCamera }"
							class="white--text text-center mx-auto rounded-circle"
							@click="mouseoverCamera = false; cameraOn();"
						>
							<v-icon
								x-large
								dark
								class="mt-11"
							>
								mdi-camera
							</v-icon>
							<br/>
							Capture
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
			
			<v-card-text class="pa-0 ma-0" v-if="dialogContent == 'camera'">
				
				<v-row dense no-gutters justify="center">
					<v-col cols="11" class="text-center">
						<v-row dense no-gutters class="mt-5 pb-5" justify="space-around">
							<v-col cols="6" class="text-center text-h6">Camera</v-col>
							<v-col cols="6" class="text-center text-h6">Preview</v-col>
							
							<v-col cols="6" class="text-center pr-1">
								<div v-bind:class="{ 'outer': !cameraOpen }">
									<div v-bind:class="{ 'home-top-video': !cameraOpen }">
										<video
											ref="video"
											id="video"
											autoplay
											style="max-width: 100%; height: auto;"
											class="ma-0 pa-0"
										></video>
										<div v-show="!cameraOpen" v-bind:class="{ 'home-text': !cameraOpen }">
											<h3>{{ cameraState }}</h3>
										</div>
									</div>
								</div>
							</v-col>
							
							<v-col cols="6" class="text-center pl-1 pb-1">
								<canvas
									ref="canvas"
									id="canvas"
									style="border: 1px solid grey; width: 100%; height: 99%;"
									class="ma-0 pa-0"
								></canvas>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				
			</v-card-text>
			
			<v-card-text class="pa-0 ma-0" v-if="dialogContent == 'crop'" style="background-color:#7f7f7f;">
				<v-row dense no-gutters justify="space-around">
					<v-col cols="auto" class="pt-1 pb-2">
						<VueCropper
							v-show="selectedFile"
							ref="cropper"
							:aspectRatio="1/1"
							alt="Source Image"
							:src="selectedFile"
							:imgStyle="cropImgStyle"
							:cropContainerStyle="cropContainerStyle"
							@zoom="cropZoomOff"
						></VueCropper>
					</v-col>
				</v-row>
			</v-card-text>
			
			<v-card-actions class="primary pa-0 ma-0">
				<v-row dense no-gutters v-if="dialogContent == 'crop'">
					<v-col cols="6" class="text-center">
						<v-btn block dark text x-large @click="dialogContent = 'select'">BACK</v-btn>
					</v-col>
					<v-col cols="6" class="text-center">
						<v-btn block dark text x-large :loading="loadingUpload" @click="cropSave">UPLOAD</v-btn>
					</v-col>
				</v-row>
				
				<v-row dense no-gutters v-if="dialogContent == 'camera'">
					<v-col cols="3">
						<v-btn block dark text x-large @click="cameraOff(); dialogContent = 'select';">BACK</v-btn>
					</v-col>
					<v-col cols="3">
						<v-btn block dark text x-large @click="cameraSnap">CAPTURE</v-btn>
					</v-col>
					<v-col cols="3">
						<v-btn block dark text x-large @click="cameraCrop">CROP</v-btn>
					</v-col>
					<v-col cols="3">
						<v-btn block dark text x-large :loading="loadingUpload" @click="cameraSave">UPLOAD</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
		
		<v-snackbar
			v-model="snackBar"
			color="error"
			bottom
			timeout="9000"
			class="text-h3 font-weight-bold"
		>
			Sorry, FileReader API not supported
			<template v-slot:action="{ attrs }">
				<v-icon small v-bind="attrs" @click="snackBar = false">mdi-close-circle</v-icon>
			</template>
		</v-snackbar>
	</v-dialog>
		
</template>
<script>

import { mapGetters } from 'vuex';
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { UPLOAD_PROFILE_CLOSE, IMAGE_PROFILE_LOAD } from '@/store/actions/user';

var timeoutVideo = '';

export default {
	components: { VueCropper },
	data: () => ({
		snackBar			: false,
		
		mouseoverBrowse 	: false,
		mouseoverCamera		: false,
		loadingUpload		: false,
		
		cameraError			: false,
		cameraClick			: false,
		cameraOpen			: false,
		cameraState			: 'Detecting camera',
		
		cropedImage			: '',
		selectedFile		: '',
		cropImgStyle		: {
			width		: '400px',
			height		: '400px',
		},
		cropContainerStyle	:{
			background	: 'transparent',
		},
		dialogContent		: 'select',
		dialogTitleArr	: [
			{key: 'select',		val: 'Upload picture'},
			{key: 'crop',		val: 'Crop picture'},
			{key: 'camera',		val: 'Capture picture'},
		],
		
		video				: {},
		canvas				: {},
		
		text: 'here',
	}),
	methods: {
		loadImage(){
			this.$store.dispatch(IMAGE_PROFILE_LOAD);
		},
		cameraOn(){
			this.cameraError = false;
			this.cameraClick = false;
			
			this.cameraState = 'Detecting camera';
			this.cameraOpen = false;
			
			// Get access to the camera!
			if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			
				this.dialogContent = 'camera';
				clearTimeout(timeoutVideo);
				
				timeoutVideo = setTimeout(() => {
					this.video = this.$refs.video;
					// Not adding `{ audio: true }` since we only want video now
					navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream) => {
						//video.src = window.URL.createObjectURL(stream);
						//window.localStream = stream;
						this.video.srcObject = stream;
						this.video.play();
						
						this.cameraOpen = true;
					}).catch(() => {
						this.cameraOpen = false;
						this.cameraError = true;
						this.cameraState = 'Camera not detected';
					});
				
				}, 500);
			}else{
				this.cameraOpen = false;
				this.cameraError = true;
				this.cameraState = 'Camera not detected';
				this.$refs.mobileCamera.click();
			}
		},
		cameraSnap(){
			if(this.cameraOpen){
				var canvas = this.$refs.canvas;
				canvas.width = this.video.videoWidth;
				canvas.height = this.video.videoHeight - 8;
				var canvasContext = canvas.getContext("2d");
				canvasContext.drawImage(this.video, 0, 0);
				
				this.cameraClick = true;
			}
		},
		cameraSave(){
			if(this.cameraClick){
				this.cropedImage = this.$refs.canvas.toDataURL("image/jpeg", 1.0);
				
				// form data
				let formData = new FormData();
				formData.append('file', this.cropedImage);
				
				this.loadingUpload = true;
				
				this.$http.post('profilePicture', formData)
				.then(() => {
					this.loadImage();
					this.uploadProfileOff();
				})
				.finally(() => {
					this.loadingUpload = false;
				});
			}
		},
		cameraCrop() {
			if(this.cameraClick){
				this.cameraOff();
				this.cropedImage = this.$refs.canvas.toDataURL("image/jpeg", 1.0);
				
				if (typeof FileReader === 'function') {
					this.dialogContent = 'crop'
					const reader = new FileReader()
					reader.onload = () => {
						//pre load image so crop will work
						this.selectedFile = "noImage.jpg";
						this.$refs.cropper.replace(this.selectedFile);
						
						this.$refs.cropper.replace(this.cropedImage);
					}
					var f = new File([this.cropedImage.replace('data:image/jpeg;base64,', '')], "sample.jpg", {type: "image/jpeg"})
					reader.readAsDataURL(f);
				}else{
					this.snackBar = true;
				}
			}
		},
		cropZoomOff(e){
			e.preventDefault();
		},
		cropImage(e) {
			//image to crop
			const file = e.target.files[0]
			
			if (typeof FileReader === 'function') {
				this.dialogContent = 'crop'
				const reader = new FileReader()
				reader.onload = (event) => {
					this.selectedFile = event.target.result
					this.$refs.cropper.replace(this.selectedFile);
				}
				reader.readAsDataURL(file)
			}else{
				this.snackBar = true;
			}
		},
		cropSave() {
			//save crop photo
			this.cropedImage = this.$refs.cropper.getCroppedCanvas(
				{ fillColor: '#fff', minWidth: 400, minHeight: 400, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' }
			).toDataURL("image/jpeg", 1.0)
			
			let formData = new FormData();
			formData.append('file', this.cropedImage);
			
			this.loadingUpload = true;
			
			// send upload request
			this.$http.post('profilePicture', formData)
			.then(() => {
				this.loadImage();
				this.uploadProfileOff();
			}).finally(() => {
				this.loadingUpload = false;
			});
		},
		
		cameraOff(){
			const video = this.$refs.video;
			
			if(typeof video !== 'undefined' && this.cameraOpen){
				// A video's MediaStream object is available through its srcObject attribute
				const mediaStream = video.srcObject;

				if(mediaStream !== null){
					// Through the MediaStream, you can get the MediaStreamTracks with getTracks():
					const tracks = mediaStream.getTracks();

					// Tracks are returned as an array, so if you know you only have one, you can stop it with: 
					tracks[0].stop();

					// Or stop all like so:
					tracks.forEach(track => track.stop());
				}
			}
		},
		uploadProfileOff(){
			this.cameraOff();
			
			this.cropedImage	= '';
			this.selectedFile	= '';
			this.dialogContent	= 'select';
			
			this.$store.dispatch(UPLOAD_PROFILE_CLOSE);
		},
	},
	computed: {
		...mapGetters(['uploadProfile', 'errorOn', 'errorType', 'errorMessage']),
		dialogTitle(){
			return this.dialogTitleArr.find(({ key }) => key === this.dialogContent).val;
		}
	},
}
</script>

<style scoped>

	>>> .outer {
		text-align: center;
		position: relative;
		overflow: hidden;
		height:100%;
	}
	
	>>> .home-top-video:before{
		content:"";
		position: absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		background:linear-gradient(grey, grey, grey);
	}
	
	>>> .home-top-video {
		height: 97%;
		width: 100%;
		overflow: hidden;
		position: absolute;
	}
	
	>>> .home-text {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		color: #fff;
	}
	
</style>